
            @import 'src/styles/mixins.scss';
        
.beforeStyle {
    position: relative;
    &:hover {
        text-decoration: none;
        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            border-bottom: 3px solid #3f7fea;
            padding-top: 3px;
            width: 100%;
        }
    }
}
